import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Image } from './Images'
import { media } from './Styles'
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import styled from "styled-components"
import { useI18next } from 'gatsby-plugin-react-i18next'

import more from "../images/nav/more.svg"

const Related = ({ data, projectId }) => {
    const { language } = useI18next()

    const breakpoints = useBreakpoint()
    const [project, setProject] = useState('')
    const [projectInfo, setProjectInfo] = useState(false)
    const [projectKey, setProjectKey] = useState(null)
    const [projects] = useState(data)
    const [relatedProjects, setRelatedProjects] = useState('')

    // GET PROJECT
    useEffect(() => {
        projects.map(project => project.id === projectId ? setProject(project) : '')
        setRelatedProjects(project.relatedProjects)
    },[setRelatedProjects, relatedProjects, projectId, projects, project.relatedProjects])

    return (
        <StyledRelated>
            <h3 className='title' dangerouslySetInnerHTML={{ __html: language === 'pt' ? 'Projetos Relacionados' : 'Related Projects' }}></h3>
            <div className='projects'>
                {relatedProjects !== '' &&
                    project.relatedProjects.map(relatedProjects => 
                        projects.map((itemData, i) => (
                            itemData.id === relatedProjects.projectId &&
                                <Link
                                    to={itemData.link}
                                    key={"projeto" + i}
                                    className="item"
                                    id={"project" + i}
                                >
                                    <Image
                                        src={itemData.image}
                                        style={{ height: `100%` }}
                                        onMouseEnter={() => { setProjectInfo(true); setProjectKey(i) }}
                                    />
                                    {((projectInfo && projectKey === i) || breakpoints.l) &&
                                        <div  role="button" tabIndex={0}
                                            className="cardContent"
                                            onMouseLeave={() => { setProjectInfo(false); setProjectKey(null) }}
                                        >
                                            {itemData.title && 
                                                <h3>{itemData.title}</h3>
                                            }
                                            {itemData.logo && 
                                                <Image
                                                    src={itemData.logo}
                                                    alt={itemData.alt ? itemData.alt : ''}
                                                    onMouseEnter={() => { setProjectInfo(true); setProjectKey(i) }}
                                                />
                                            }
                                            <img src={more} alt="More" className='img-more'/>
                                        </div>
                                    }
                                </Link>
                        ))
                    )
                }
            </div>
        </StyledRelated>
    )
}

export default Related

const StyledRelated = styled.div`
    position:relative;
    padding: clamp(50px,10vw,90px) 0;

    .title{
        font-weight:600;
        font-size:3rem;
        text-align:center;
        color:#363636;
    }

    .projects{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 5rem 5%;
        margin: 0 7.3%;
        padding: clamp(50px,10vw,90px) 0;

        ${media.m`
            margin: unset;
            padding: clamp(50px,10vw,50px) 7.3%;
            gap: 2.5rem 5%;
        `}

        ${media.s`
            grid-template-columns: 1fr;
        `}

        .item {
            position: relative;
            text-transform: uppercase;
            max-height:50vh;
        }

        .cardContent {
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-content:center;
            gap:2rem;
            background-color: #2c2c2cB3;
            padding: 5%;
            text-align:center;
            color:#fff;

            h3{
                font-weight:600;
                max-width: 500px;
                font-size:1.9rem;

                ${media.xxl`
                    font-size:1.5rem;
                `}

                ${media.xl`
                    font-size:1.2rem;
                `}

                ${media.s`
                    font-size:1rem;
                `}
            }

            .image{
                width:100%;
                max-width:15vw;

                ${media.l`
                    max-width:40vw;
                `}
            }

            .img-more{
                max-width:40px;
                border-radius: 50%;
                transition: 0.3s;

                :hover{
                    background: #ebebeb80;
                }
            }
        }  
    }
`
